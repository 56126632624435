import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BsFacebook, BsInstagram, BsYoutube, BsTwitterX } from "react-icons/bs";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import dayjs from "dayjs";

export const Footer = () => {
  const date = dayjs().format("YYYY");

  return (
    <>
      <div className="footer">
        <Container>
          <Row>
            <Col md={4} lg={4} sm={12} xs={12}>
              <Iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15866.719905272072!2d39.187096297650385!3d-6.173583351019646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185cd16cea05e671%3A0xb78f169731fc35c!2sZAECA!5e0!3m2!1sen!2stz!4v1698943807107!5m2!1sen!2stz"
                // position="absolute"
                width="100%"
                id="myId"
                className="myClassname"
                height="250"
              ></Iframe>
            </Col>
            <Col md={4} lg={4} sm={12} xs={12}>
              <h6>Contact</h6>
              <>HQ Mobile: +255774824242</>
              <br />
              <>Kilimani Mnara wa mbao-Zanzibar, Tanzania</>
              <br />
              <>Email: info@zaeca.go.tz</>
              <br />
              <>Fax +255242231461</>
              <br />
              <>Toll Free: 113</>
              <p className="social">
                <Link
                  to="https://www.facebook.com/profile.php?id=100068866193864&mibextid=ZbWKwL"
                  target="new"
                >
                  <BsFacebook style={{ color: "#0766ff" }} />
                </Link>
                &nbsp; &nbsp;
                <Link
                  to={"https://x.com/ZaecaZ?t=Y0AjEBQflqCkVs6VuXuURQ&s=09"}
                  target="new"
                >
                  <BsTwitterX style={{ color: "white" }} />
                </Link>
                &nbsp; &nbsp;
                <Link to={"https://www.instagram.com/zaeca.znz"} target="new">
                  <BsInstagram style={{ color: "#c8325c" }} />
                </Link>
                &nbsp; &nbsp;
                <Link
                  to={
                    "https://youtube.com/@zaecaonlinetv7808?si=Jn07JOlfGvtdfv7i"
                  }
                  target="new"
                >
                  <BsYoutube style={{ color: "#ff1512" }} />
                </Link>
              </p>
            </Col>
            <Col md={4} lg={4} sm={12} xs={12}>
              <h6>Quick Links</h6>
              <>
                <Link target="new" to={"https://www.pccb.go.tz/"}>
                  PCCB (TAKUKURU)
                </Link>
              </>
              <br />
              <>
                <Link target="new" to={"https://ethicscommission.go.tz/"}>
                  TUME YA MAADILI
                </Link>
              </>
              <br />
              <>
                <Link target="new" to={"https://www.polisi.go.tz/"}>
                  POLICE TANZANIA
                </Link>
              </>
              <br />
              <>
                <Link target="new" to={"https://ocagz.go.tz/"}>
                  CAG
                </Link>
              </>
              <br />
              <>
                <Link target="new" to={"https://judiciaryzanzibar.go.tz/web/"}>
                  MAHAKAMA ZANZIBAR
                </Link>
              </>
              <br />
              <>
                <Link target="new" to={"https://baselgovernance.org/"}>
                  BASEL ISTITUTE
                </Link>
              </>
              <br />
              <>
                <Link target="new" to={"https://ethicscommission.go.tz/sw/"}>
                  ZANZIBAR PUBLIC LEADERS ETHICS COMMISSION
                </Link>
              </>
              <br />
              <>
                <Link target="new" to={"https://www.dppznz.go.tz/"}>
                  DPP
                </Link>
              </>
              <br />
              <a href="https://info.flagcounter.com/gHpu">
                <img
                  src="https://s01.flagcounter.com/count2/gHpu/bg_FFFFFF/txt_000000/border_CCCCCC/columns_4/maxflags_20/viewers_0/labels_0/pageviews_1/flags_0/percent_0/"
                  alt="Flag Counter"
                  border="0"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid={true} className="footerBottom">
        Copyright {date} @zaeca.go.tz
      </Container>
    </>
  );
};
