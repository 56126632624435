import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { DateFormat } from "../utility/dateFormat";
// import image from "../../asset/hot1.png";
// import image2 from "../../asset/b1.png";
import { ImAttachment } from "react-icons/im";
import { baseURL } from "../../action/baseUrl";

export const ReadNews = (props) => {
  const { read, setRead, dataa } = props;
  const { t } = useTranslation();
  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";

  const handleClose = () => setRead(false);

  return (
    <>
      <Modal
        size="lg"
        show={read}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        centered
      >
        <>
          <Modal.Header closeButton>
            <Modal.Title>News</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>{defaultLocale === "en" ? dataa?.engTitle : dataa?.title}</h3>
            <br />
            {dataa?.img && (
              <>
                <img src={`${baseURL}/${dataa?.img}`} alt="" width={"100%"} />
                <br />
                <br />
              </>
            )}
            <p style={{ lineHeight: "2.3rem" }}>
              {defaultLocale === "en" ? dataa?.engDetails : dataa?.details}
              {/* {parse(newsBody)} */}
            </p>
            <span>
              {dataa?.file && (
                <a
                  href={`${baseURL}/${dataa?.file}`}
                  download=""
                  className="btn btn-default"
                  target="new"
                >
                  {t("Attach")} <ImAttachment />
                </a>
              )}

              <small style={{ float: "right", fontStyle: "italic" }}>
                {/* Posted at <DateFormat date={dataa?.created_at} /> */}
                {t("post")} <DateFormat date={dataa?.created_at} />
              </small>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};
