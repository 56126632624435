import React from "react";
import { Container } from "react-bootstrap";
import { BlinkBlur, ThreeDot } from "react-loading-indicators";

const LoadingComp = () => {
  return (
    <Container style={{ textAlign: "center" }}>
      <br />
      <ThreeDot
        variant="bob"
        color="#32cd32"
        text="Loading ..."
        size="medium"
        textColor="#32cd32"
      />
    </Container>
  );
};

export default LoadingComp;
