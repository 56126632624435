import React, { useEffect, useState } from "react";
import { listVideos } from "../../action/video";
import { Card, Col, Row, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { LoadingComp, Pagination, Error } from "../utility";
import { Link } from "react-router-dom";
import { baseURL } from "../../action/baseUrl";
import NewPagination from "../utility/NewPagination";

const VideosComponent = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(1);
  const [index, setIndex] = useState(0);

  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";

  const { t } = useTranslation();

  const video = async (page) => {
    const response = await axios.get(`${baseURL}/api/video?page=${page}`);
    return response.data;
  };

  // const { data: videoRec, isLoading, isError } = useQuery("videoData", video);
  const { isLoading, isError, data, isFetching, error, isPlaceholderData } =
    useQuery({
      queryKey: ["videoNews", currentPage],
      queryFn: () => video(currentPage),
      placeholderData: keepPreviousData,
    });

  console.error(data?.data);
  console.warn("");

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // page(selectedPage + limit);
  };

  return (
    <div>
      <Row>
        {isError && <Error />}
        {isLoading && <LoadingComp />}

        {data?.data?.data?.map((dt, i) => (
          <Col lg={4} md={4} sm={6} xs={12} key={i}>
            <Card style={{ border: "none" }}>
              <Card.Body style={{ padding: "0" }}>
                <ReactPlayer url={dt?.file} width={"100%"} />
                <div className="fw-bold">
                  {defaultLocale === "en" ? dt?.engDetails : dt?.details}
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <div className="container" style={{ display: "flex" }}>
        {/* <button onClick={() => fetchNextPage()}>Load More</button> */}
        <NewPagination
          currentPage={currentPage}
          total={data?.data.total}
          perPage={data?.data.per_page}
          isPlaceholderData={isPlaceholderData}
          onPageChange={(page) => setCurrentPage(page)}
          isLoading={isLoading}
          isFetching={isFetching}
        />
        {/* {isFetching ? <span> Loading...</span> : null} */}

        {/* <Pagination
          dataLength={data?.data?.total}
          pageCount={data?.data?.last_page}
          handlePageClick={handlePageClick}
        /> */}
      </div>
    </div>
  );
};

export default VideosComponent;
