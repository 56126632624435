import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Spinner, Row, Breadcrumb, Card } from "react-bootstrap";
import { listRedio } from "../../action/Redio";
import axios from "axios";
// import { useQuery } from "react-query";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { DateFormat } from "../utility/dateFormat";
import { Pagination, LoadingComp, Error } from "../utility";
import { baseURL } from "../../action/baseUrl";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import NewPagination from "../utility/NewPagination";

export const Redio = () => {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [msgError, setMsgError] = useState(undefined);
  // const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(1);
  // const { auth } = useContext(AuthContext);
  const { t } = useTranslation();

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // setOffset(selectedPage + limit);
  };

  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";

  const radio = async (page) => {
    const response = await axios.get(`${baseURL}/api/radio?page=${page}`);
    return response.data;
  };

  // const { data: redioRec, isLoading, isError } = useQuery("radioData", radio);
  const { isLoading, isError, data, error, isFetching, isPlaceholderData } =
    useQuery({
      queryKey: ["radioData", currentPage],
      queryFn: () => radio(currentPage),
      placeholderData: keepPreviousData,
    });

  return (
    <div>
      <Row>
        {isError && <Error />}
        {isLoading && <LoadingComp />}

        {data?.data?.data?.map((dt, i) => (
          <Col lg={4} md={4} sm={12} xs={12} key={i}>
            <Card>
              <Card.Body>
                <center>
                  <audio src={`${baseURL}/${dt.file}`} controls></audio>
                </center>
                <p>
                  {/* {dt.title} */}
                  {defaultLocale === "en" ? dt?.engDetails : dt?.details}
                  <span
                    style={{
                      fontStyle: "italic",
                      fontWeight: "bold",
                      float: "right",
                      fontSize: "small",
                    }}
                  >
                    Recorded at: <DateFormat date={dt.date} />
                  </span>
                </p>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {/* {dt.body.substring(0, 150)}{" "}
          {dt.body.length > 150 && <a href="#">... read more</a>}{" "} */}

      <NewPagination
        currentPage={currentPage}
        total={data?.data.total}
        perPage={data?.data.per_page}
        isPlaceholderData={isPlaceholderData}
        onPageChange={(page) => setCurrentPage(page)}
        isLoading={isLoading}
        isFetching={isFetching}
      />

      {/* <div className="container" style={{ display: "flex" }}>
        <Pagination
          dataLength={data?.total}
          pageCount={data?.last_page}
          handlePageClick={handlePageClick}
        />
      </div> */}
    </div>
  );
};
