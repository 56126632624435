import React from 'react'
import { useTranslation } from 'react-i18next';
import {Table } from "react-bootstrap";
import { BsFileEarmarkPdf } from "react-icons/bs";
import zaeca_5_2023_sw from "../../asset/resources/act_5_2023_sw.pdf";
import zaeca_5_2023_eng from "../../asset/resources/act_5_2023_eng.pdf";
import Zanzibar_constitutional_eng from "../../asset/resources/Zanzibar_constitutional.pdf";
import katibaZanzibar1984 from "../../asset/resources/Katiba-ya-Zanzibar-ya-1984-Toleo-la-2020.pdf";
import TanzaniaConstitution from "../../asset/resources/UnitedRepuplicOfTanzaniaConstitution.pdf";
import moneyLoundering from "../../asset/resources/moneyLoundering.pdf";
import madawa_eng from "../../asset/resources/madawa_eng.pdf";
import madawa_sw from "../../asset/resources/madawa_sw.pdf";
import kmkm from "../../asset/resources/kmkm.pdf";
import cag_eng from "../../asset/resources/cag_eng.pdf";
import cag_sw from "../../asset/resources/cag_sw.pdf";
import manunuzi_eng from "../../asset/resources/manunuzi_eng.pdf";
import manunuzi_sw from "../../asset/resources/manunuzi_sw.pdf";
import fedha_eng from "../../asset/resources/usimamiziFedha_eng.pdf";
import fedha_sw from "../../asset/resources/usimamiziFedha_sw.pdf";
import maadili from "../../asset/resources/maadili.pdf";

const Law = () => {
    const [t] = useTranslation();
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th style={{ textAlign: "center" }}>Download</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>{t("Resource.lawTab.katibaZanzibar")}</td>
          <td>
            <a href={katibaZanzibar1984} target="new">
              <BsFileEarmarkPdf /> Swahili version
            </a>
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td>{t("Resource.lawTab.katibaTanzania")}</td>
          <td>
            <a href={TanzaniaConstitution} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>{t("Resource.lawTab.zaeca")}</td>
          <td>
            <a href={zaeca_5_2023_eng} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
            <br />
            <a href={zaeca_5_2023_sw} target="new">
              <BsFileEarmarkPdf /> Swahili version
            </a>
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>{t("Resource.lawTab.fedha")}</td>
          <td>
            <a href={moneyLoundering} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
          </td>
        </tr>
        <tr>
          <td>5</td>
          <td>{t("Resource.lawTab.dawa")}</td>
          <td>
            <a href={madawa_eng} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
            <br />
            <a href={madawa_sw} target="new">
              <BsFileEarmarkPdf /> Swahili version
            </a>
          </td>
        </tr>
        <tr>
          <td>6</td>
          <td>{t("Resource.lawTab.magendo")}</td>
          <td>
            <a href={kmkm} target="new">
              <BsFileEarmarkPdf /> Swahili version
            </a>
          </td>
        </tr>
        <tr>
          <td>7</td>
          <td>{t("Resource.lawTab.ukaguziUmma")}</td>
          <td>
            <a href={cag_eng} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
            <br />
            <a href={cag_sw} target="new">
              <BsFileEarmarkPdf /> Swahili version
            </a>
          </td>
        </tr>
        <tr>
          <td>8</td>
          <td>{t("Resource.lawTab.manunuzi")}</td>
          <td>
            <a href={manunuzi_eng} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
            <br />
            <a href={manunuzi_sw} target="new">
              <BsFileEarmarkPdf /> Swahili version
            </a>
          </td>
        </tr>
        <tr>
          <td>9</td>
          <td>{t("Resource.lawTab.utawalaFedha")}</td>
          <td>
            <a href={fedha_eng} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
            <br />
            <a href={fedha_sw} target="new">
              <BsFileEarmarkPdf /> Swahili version
            </a>
          </td>
        </tr>
        <tr>
          <td>10</td>
          <td>{t("Resource.lawTab.maadili")}</td>
          <td>
            <a href={maadili} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default Law
