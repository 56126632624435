import React from "react";
import { Container, Table, Accordion } from "react-bootstrap";
import { BsCloudDownloadFill } from "react-icons/bs";
import { ResourceTab } from "./resourceTab";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

export const Home = () => {
  const { t } = useTranslation();
  return (
    <Container className="space">
      <h2 className="red">{t("menu.Resources")}</h2>
      <p>{parse(t("Resource.detail"))}</p>
      <ResourceTab />
    </Container>
  );
};
