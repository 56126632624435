import React from "react";
import { BlinkBlur, ThreeDot } from "react-loading-indicators";
import { Container } from "react-bootstrap";

const Error = () => {
  return (
    <Container style={{ textAlign: "center" }}>
      <br />
      <ThreeDot
        variant="bob"
        color="#fc0000"
        text="Loading ..."
        size="medium"
        textColor="#fc0000"
      />
    </Container>
  );
};

export default Error;
