import React, { useState, useEffect } from "react";
import { LoadingComp, MyCard, NewPagination, Error } from "../utility";
import { Col, Row } from "react-bootstrap";
import { Container, Spinner, Button } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { publicBarnners } from "../../action/Barnners";
import { DateFormat } from "../utility/dateFormat";
import { baseURL } from "../../action/baseUrl";

export const Home = () => {
  const [loading, setLoading] = useState(false);
  const [msgError, setMsgError] = useState(undefined);
  // const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(1);
  const [index, setIndex] = useState(0);
  const { t } = useTranslation();
  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";

  const gallary = async (page) => {
    const response = await axios.get(`${baseURL}/api/gallery?page=${page}`);
    return response.data;
  };

  const { isLoading, isError, data, isFetching, error, isPlaceholderData } =
    useQuery({
      queryKey: ["gallaryData", currentPage],
      queryFn: () => gallary(currentPage),
    });

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // setOffset(selectedPage + limit);
  };

  return (
    <div className="space">
      <h2 className="red">{t("gallery.title")}</h2>
      <Row>
        {isError && <Error />}

        {isLoading && <LoadingComp />}

        {data?.data?.data?.map((dt, i) => (
          <Col lg={3} md={4} sm={6} xs={12} key={i}>
            <MyCard
              img={`${baseURL}/${dt.file}`}
              details={defaultLocale === "en" ? dt?.engDetails : dt?.details}
              date={<DateFormat date={dt.eventDate} />}
            />
          </Col>
        ))}
      </Row>
      <NewPagination
        currentPage={currentPage}
        total={data?.data.total}
        perPage={data?.data.per_page}
        isPlaceholderData={isPlaceholderData}
        onPageChange={(page) => setCurrentPage(page)}
        isLoading={isLoading}
        isFetching={isFetching}
      />
      <div className="container" style={{ display: "flex" }}>
        {/* <Pagination
          dataLength={data?.total}
          pageCount={data?.last_page}
          handlePageClick={handlePageClick}
        /> */}
      </div>
    </div>
  );
};
