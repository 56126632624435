import React, { useState, useEffect } from "react";
import { Card, Button, Col, Row, Spinner } from "react-bootstrap";
import { hotNewsList } from "../../action/News";
import { LoadingComp, Error } from "../utility";
import parse from "html-react-parser";
// import { useQuery } from "react-query";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import newsPic from "../../asset/annoucement.jpg";
import { ReadNews } from "./readNews";
import { baseURL } from "../../action/baseUrl";
import { useTranslation } from "react-i18next";

export const HotEvent = (props) => {
  const { t } = useTranslation();
  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";
  // const [data, setData] = useState([]);
  const [dataa, setDataa] = useState({});
  const [loading, setLoading] = useState(false);
  const [read, setRead] = useState(false);
  const [index, setIndex] = useState(1);
  const [msgError, setMsgError] = useState("");

  const retrieveHotNews = async () => {
    const response = await axios.get(`${baseURL}/api/news/hot`);
    return response.data;
  };

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["hotEvents"],
    queryFn: retrieveHotNews,
  });

  const handleReadNews = (data) => {
    console.warn(data);
    setRead(true);
    setDataa(data);
  };

  return (
    <Row>
      {isLoading && <LoadingComp />}

      {isError && <Error />}

      {data?.data?.map((dt, i) => (
        <Col lg={4} md={4} sm={12} key={i}>
          <Card className="rounded-0 cardHover">
            {dt.img === null ? (
              <Card.Img src={newsPic} />
            ) : (
              <Card.Img src={`${baseURL}/${dt.img}`} />
            )}

            <Card.Body>
              <p>
                {/* {dt.title} */}
                {defaultLocale === "en" ? dt?.engTitle : dt?.title}
              </p>
              <Card.Text></Card.Text>
              <Button
                variant="info rounded-0"
                onClick={() => {
                  handleReadNews(dt);
                }}
              >
                {t("readMore")}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      ))}
      <ReadNews
        read={read}
        setRead={setRead}
        dataa={dataa}
        // newsTitle={newsTitle}
        // newsDate={newsDate}
        // newsImage={newsImage}
      />
    </Row>
  );
};
