import React from "react";
import { Card, Button } from "react-bootstrap";

export const MyCard = (props) => {
  const { img, details, date } = props;
  return (
    <div>
      <Card>
        <Card.Img variant="top" src={img} />
        <Card.Body>
          {/* <Card.Title>{title}</Card.Title> */}
          <Card.Text>
            <p>{details}</p>
            <small style={{ float: "right", fontStyle: "italic" }}>
              {date}
            </small>
          </Card.Text>

          {/* <Button className="btn-sm" variant="warning">
            Read more ...
          </Button> */}
        </Card.Body>
      </Card>
    </div>
  );
};
