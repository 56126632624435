import React from "react";
import { Row, Col, Carousel, Container } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
// import { AwarenessComp } from "./awarenessComp";
import parse from "html-react-parser";
import { RightSide } from "../home/rightSide";
import { Link } from "react-router-dom";
import VideosComponent from "./videos";
import { Redio } from "./radio";
import { Community } from "./community";

export const Home = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="space">
      <Row>
        <Col lg={9} md={9} sm={12}>
          <h2 className="red">{t("intro")}</h2>
          <p>{parse(t("uelimishaji.detail"))}</p>
          <h4 className="red">{t("effort")}</h4>

          <hr />
          <h5>{t("uelimishaji.tv")}</h5>
          <VideosComponent />
          <hr />
          <h5>{t("uelimishaji.redio")}</h5>
          <Redio />
          <hr />
          <h5>{t("uelimishaji.elimu")}</h5>
          <Community />
          <hr />
          <h5>{t("uelimishaji.seminar")}</h5>
          <hr />
        </Col>
        <Col lg={3} md={3} sm={12}>
          <RightSide />
        </Col>
      </Row>

      {/* <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Number</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Radios Programs</td>
            <td>67</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Televisions Programs</td>
            <td>09</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Meeting with Leaders of Ministry</td>
            <td>08</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Meeting with Police Force</td>
            <td>01</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Seminar</td>
            <td>06</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Meeting with Entreprenuers</td>
            <td>01</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Meeting with Secondary Schools's Students</td>
            <td>09</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Exhibitions</td>
            <td>03</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Celebrations</td>
            <td>01</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Meeting with Local Government Leaders</td>
            <td>10</td>
          </tr>
        </tbody>
      </Table> */}
    </div>
  );
};
