import React, { useState } from "react";
import { Tab, Tabs, Card, Table } from "react-bootstrap";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import Law from "./law";
import InternationalLaws from "./internationalLaws";
import Mikakati from "./mikakati";
import Kanuni from "./kanuni";
import Publication from "./publication";
import Other from "./other";

export const ResourceTab = () => {
  const [key, setKey] = useState("home");
  const [t] = useTranslation();

  return (
    <Card>
      <Card.Header as="h4">{t("menu.Resources")}</Card.Header>
      <Card.Body>
        <Tabs
          defaultActiveKey="law"
          transition={true}
          id="noanim-tab-example"
          className="mb-3 text-secondary"
        >
          <Tab
            eventKey="law"
            className="text-secondary"
            title={t("Resource.law")}
          >
            <Law />
          </Tab>
          <Tab
            eventKey="kanuni"
            className="text-secondary"
            title={t("Resource.kanuni")}
          >
            <Kanuni />
          </Tab>
          <Tab eventKey="kimataifa" title={t("Resource.kimataifa")}>
            <InternationalLaws />
          </Tab>
          <Tab eventKey="strategies" title={t("Resource.strategies")}>
            <Mikakati />
          </Tab>
          <Tab eventKey="publication" title={t("Resource.publication")}>
            <Publication />
          </Tab>
          <Tab eventKey="other" title={t("Resource.other")}>
            <Other />
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
  );
};
