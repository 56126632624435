import React, { useEffect } from "react";
import swMuundo from "../../asset/swMuundo.png";
import engMuundo from "../../asset/engMuundo.png";
import { useTranslation } from "react-i18next";

export const OrgStructure = () => {
  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";
  const { t } = useTranslation();
  useEffect(() => {}, [t]);
  return (
    <div className="space">
      {defaultLocale === "en" ? (
        <img src={engMuundo} width={"100%"} />
      ) : (
        <img src={swMuundo} width={"100%"} />
      )}
    </div>
  );
};
