import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser"

export const DGMessage = (props) => {
  const { show, setShow } = props;
  const handleClose = () => setShow(false);
  const [t] = useTranslation();

  return (
    <>
      <Modal
        size="lg"
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        centered
      >
        <>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <h3>{parse(t("DG.title"))}</h3>
            </center>
            <p>{parse(t("DG.message"))}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};
