import React from "react";
import { Barner, Welcome, RightSide, LeftSide } from "../component/home";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Header, Menu, Footer } from "../component";
import "../index.css";

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>

      <Container
        fluid={true}
        className=""
        style={{
          padding: "0px",
        }}
      >
        <Menu />
        <Container>
          <Row>
            <Col md={9} lg={9} sm={12}>
              <Welcome />
            </Col>
            <Col md={3} lg={3} sm={12}>
              <RightSide />
            </Col>
          </Row>
          <br />
        </Container>
      </Container>
    </>
  );
};
