import { BrowserRouter } from "react-router-dom";
import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { HelmetProvider } from "react-helmet-async";
import { WebRouter } from "./route";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import dotenv from "dotenv";

function App() {
  // dotenv.config();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);
  const helmetContext = {};
  // const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
  // const queryClient = new QueryClient();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 60 * 30000, // 1 minute,
      },
    },
  });

  return (
    <>
      <HelmetProvider context={helmetContext}>
        {loading ? (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              height: "100vh",
            }}
          >
            <ClipLoader
              color={"#FF0033"}
              loading={loading}
              // cssOverride={}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <Fragment>
                <WebRouter />
              </Fragment>
            </BrowserRouter>
          </QueryClientProvider>
        )}
      </HelmetProvider>
    </>
  );
}

export default App;
