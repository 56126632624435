import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

export const Home = () => {
  const [t] = useTranslation();
  return (
    <div className="space">
      <h2 className="red">{t("background.intro")}</h2>
      <p>{t("background.one")}</p>
      <p>{t("background.two")}</p>
      <h3>{t("background.three")}</h3>
      <p>{t("background.four")}</p>
      <p>{parse(t("background.five.0"))}</p>
      <p>{t("background.six.0")}</p>
      <p>{parse(t("background.six.1"))}</p>
      <p>{parse(t("background.seven"))}</p>
    </div>
  );
};
