import React, { useEffect } from "react";
import { Container, Col, Accordion, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

export const CoreValue = () => {
  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";

  const { t } = useTranslation();
  useEffect(() => {}, [t]);
  return (
    <Container className="space">
      {/* <h3>CORE VALUES</h3> */}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{parse(t("coreValue.core"))}</Accordion.Header>
          <Accordion.Body>
            <p>{parse(t("coreValue.coreDetail"))}</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            {parse(t("responsible.responsible"))}
          </Accordion.Header>
          <Accordion.Body>
            <p>{parse(t("responsible.responsibleDetail"))}</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{parse(t("assure.assure"))}</Accordion.Header>
          <Accordion.Body>
            <p>{parse(t("assure.assureDetail"))}</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};
