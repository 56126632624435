import React, { useState } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { addComplain } from "../../action/Complain";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

const productSchema = Yup.object().shape({
  name: Yup.string().min(3),
  phone: Yup.string().min(10).max(10),
  details: Yup.string().required("Complain required"),
  file: Yup.mixed().test(
    "fileSize",
    "File size too large, max file size is 1 MB",
    (file) => {
      if (file) {
        return file.size <= 1024 * 1024;
      } else {
        return true;
      }
    }
  ),
});

const initialValues = {
  name: "",
  phone: "",
  details: "",
  address: "",
  file: "",
};

export const ComplainForm = (props) => {
  const { t } = useTranslation();
  const { show, setShow } = props;
  const [isDisabled, setIsDisable] = useState(false);
  const [captchaCode, setCaptchaCode] = useState(null);
  // const [file, setFile] = useState(null);

  const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
      // Authorization: "Bearer" + auth?.access_token,
    },
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    setCaptchaCode(value);
  }

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const formik = useFormik({
    initialValues,
    validationSchema: productSchema,

    onSubmit: (values, { setSubmitting, resetForm }) => {
      // setLoading(true)
      const { name, phone, details, address, file, image } = values;
      const data = { name, phone, details, address, file, image };
      console.warn(data);

      setTimeout(() => {
        setIsDisable(true);
        data["source"] = "Online";
        addComplain(data, headers)
          .then((resp) => {
            // setIndex(index + 1);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            Toast.fire({
              icon: "success",
              title: "Taarifa yako imetumwa kikamilifu",
            });
          })
          .catch((error) => {
            console.log(error);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            Toast.fire({
              icon: "error",
              title:
                "Samahani kuna shida katika mfumo tafadhali tuma tena lalamiko lako",
            });
          })
          .finally(() => {
            setSubmitting(false);
            resetForm();
            setIsDisable(false);
            setShow(false);
          });
      }, 10);
    },
  });

  return (
    <>
      <Modal
        size="lg"
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        centered
      >
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{t("Comp_form.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>
                <span>{t("Comp_form.name")}</span>
              </Form.Label>
              <Form.Control
                type="tetx"
                placeholder={t("Comp_form.name")}
                autoFocus
                {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="mt-2 fst-italic fw-normal h6">
                  <span className="text-danger">{formik.errors.name}</span>
                </div>
              )}
            </Form.Group>
            <Row>
              <Col md={6} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Comp_form.phone")}</Form.Label>
                  <Form.Control
                    type="tetx"
                    placeholder="Ex: 0XXXXXXXXX"
                    {...formik.getFieldProps("phone")}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className="mt-2 fst-italic fw-normal h6">
                      <span className="text-danger">{formik.errors.phone}</span>
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <span>
                      {t("Comp_form.address")}{" "}
                      {/* <small className="text-danger">*</small> */}
                    </span>
                  </Form.Label>
                  <Form.Select
                    // className="form-control"
                    aria-label="Default select example"
                    {...formik.getFieldProps("address")}
                  >
                    <option value="">Chagua mkoa</option>
                    <option>Kaskazini Pemba</option>
                    <option>Kaskazini Unguja</option>
                    <option>Kusini Pemba </option>
                    <option>Kusini Unguja</option>
                    <option>Mjini Magharibi</option>
                    <option>Nje ya Zanzibar</option>
                  </Form.Select>
                  {formik.touched.address && formik.errors.address && (
                    <div className="mt-2 fst-italic fw-normal h6">
                      <span className="text-danger">
                        {formik.errors.address}
                      </span>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>
                <span>
                  {t("Comp_form.complain")}
                  <small className="text-danger">*</small>
                </span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                placeholder={t("Comp_form.complain")}
                {...formik.getFieldProps("details")}
              />
              {formik.touched.details && formik.errors.details && (
                <div className="mt-2 fst-italic fw-normal h6">
                  <span className="text-danger">{formik.errors.details}</span>
                </div>
              )}
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>{t("Comp_form.attach")}</Form.Label>
              <Form.Control
                type="file"
                accept=".png,.jpg,.jpeg,.pdf,.csv,.xls,.xlsx"
                onChange={(e) =>
                  formik.setFieldValue("file", e.target.files[0])
                }
              />
              {formik.touched.file && formik.errors.file && (
                <div className="mt-2 fst-italic fw-normal h6">
                  <span className="text-danger">{formik.errors.file}</span>
                </div>
              )}
            </Form.Group>
            <ReCAPTCHA
              sitekey="6Ld22lMqAAAAAMTB8cMH6OgOLd6tivKUnedkQBwu"
              onChange={onChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t("Comp_form.cancel")}
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={isDisabled || captchaCode === null}
            >
              {isDisabled === true ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </>
              ) : (
                t("Comp_form.submit")
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
