import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import { BsFileEarmarkPdf } from "react-icons/bs";
import NACSAP_I from "../../asset/resources/Awamu-ya-Kwanza-NACSAP-I.pdf";
import NACSAP_II from "../../asset/resources/Mkakati-wa-pili-NACSAP-II.pdf";
import NACSAP_III from "../../asset/resources/NACSAP_III_SWAHILI2.pdf";
import NACSAP_IV from "../../asset/resources/NACSAP-IV-ENGLISH.pdf";
import ANTI_CORRUPTION_STRATEGY from "../../asset/resources/NATIONAL ANTI CORRUPTION STRATEGY.pdf";
import ZADEP from "../../asset/resources/zadep.pdf";
import DEVELOPMENT_VISION from "../../asset/resources/DEVELOPMENT _VISION.pdf";

const Mikakati = () => {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th style={{ textAlign: "center" }}>Download</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>
            THE NATIONAL ANTI-CORRUPTION STRATEGY AND ACTION PLAN - I (NACSAP I)
          </td>
          <td>
            <a href={NACSAP_I} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td>
            THE NATIONAL ANTI-CORRUPTION STRATEGY AND ACTION PLAN - II (NACSAP
            II)
          </td>
          <td>
            <a href={NACSAP_II} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>
            THE NATIONAL ANTI-CORRUPTION STRATEGY AND ACTION PLAN - III (NACSAP
            III)
          </td>
          <td>
            <a href={NACSAP_III} target="new">
              <BsFileEarmarkPdf /> Swahili version
            </a>
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>
            THE NATIONAL ANTI-CORRUPTION STRATEGY AND ACTION PLAN - IV (NACSAP
            IV)
          </td>
          <td>
            <a href={NACSAP_IV} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
          </td>
        </tr>
        <tr>
          <td>5</td>
          <td>
            ZANZIBAR STRATEGY FOR ANTI-CORRUPTION AND ECONOMIC CRIMES
            (2017-2022)
          </td>
          <td>
            <a href={ANTI_CORRUPTION_STRATEGY} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
          </td>
        </tr>
        <tr>
          <td>6</td>
          <td>
            MONITORING & EVALUATION FRAMEWORK FOR THE ZANZIBAR DEVELOPMENT PLAN
            (ZADEP)
          </td>
          <td>
            <a href={ZADEP} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
          </td>
        </tr>
        <tr>
          <td>7</td>
          <td>Zanzibar Development Vision 2050</td>
          <td>
            <a href={DEVELOPMENT_VISION} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default Mikakati;
