import React from 'react'
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Menu } from '../component';
import VideosComponent from '../component/awareness/videos';

const VideoPage = () => {
  return (
    <Container
      fluid={true}
      className=""
      style={{
        padding: "0px",
        // paddingBottom: "50px",
        // background: "#eff8fc",
      }}
    >
      <Helmet>
        <title>Video</title>
      </Helmet>
      <Menu />
      <Container>
        <VideosComponent />
      </Container>
      <br />
    </Container>
  );
}

export default VideoPage;
