import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Tab, Container, Card, Accordion } from "react-bootstrap";
import parse from "html-react-parser";

export const MissionVission = () => {
  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";

  const { t } = useTranslation();
  useEffect(() => {}, [t]);

  return (
    <Container className="space">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t("missionVision.mission")}</Accordion.Header>
          <Accordion.Body>
            <p>{t("missionVision.missionDetail")}</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t("missionVision.vission")}</Accordion.Header>
          <Accordion.Body>
            <p>{t("missionVision.vissionDetail")}</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{t("missionVision.ethicsTitle")}</Accordion.Header>
          <Accordion.Body>
            <p>{parse(t("missionVision.ethicsDetail"))}</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            {t("missionVision.standardTittle")}
          </Accordion.Header>
          <Accordion.Body>
            <p>{parse(t("missionVision.standardDetail"))}</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* <Card>
        <Card.Header as="h4">{t("missionVision.title")}</Card.Header>
        <Card.Body>
          <Tabs
            defaultActiveKey="Mission"
            transition={true}
            id="noanim-tab-example"
            className="mb-3 text-secondary"
          >
            <Tab
              eventKey="Mission"
              className="text-secondary"
              title={t("missionVision.mission")}
            >
              <p>{t("missionVision.missionDetail")}</p>
            </Tab>
            <Tab
              eventKey="Vission"
              className="text-secondary"
              title={t("missionVision.vission")}
            >
              <p>{t("missionVision.vissionDetail")}</p>
            </Tab>
            <Tab
              eventKey="Code of Ethics"
              className="text-secondary"
              title={t("missionVision.ethicsTitle")}
            >
              <p>{t("missionVision.ethics")}</p>
              <p>{parse(t("missionVision.ethicsDetail"))}</p>
            </Tab>
            <Tab
              eventKey="Performance Standards"
              className="text-secondary"
              title={t("missionVision.standardTittle")}
            >
              <p>{t("missionVision.standard")}</p>
              <p>{parse(t("missionVision.standardDetail"))}</p>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card> */}
    </Container>
  );
};
