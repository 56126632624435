import React from "react";
import { Container, Button, ButtonGroup } from "react-bootstrap";
import { ThreeDot } from "react-loading-indicators";

const NewPagination = ({
  currentPage,
  total,
  onPageChange,
  perPage,
  isPlaceholderData,
  isFetching,
  isLoading,
}) => {
  const handlePreviousClick = () => onPageChange(currentPage - 1);
  const handleNextClick = () => onPageChange(currentPage + 1);

  const pageCount = Math.ceil(total / perPage);

  return (
    <Container>
      <ButtonGroup className="mb-2">
        <Button
          className="btn-dark"
          disabled={currentPage === 1 || isPlaceholderData || isLoading}
          onClick={handlePreviousClick}
        >
          &laquo;
        </Button>
        <Button className="btn-secondary">Page {currentPage}</Button>
        <Button
          className="btn-dark"
          onClick={handleNextClick}
          disabled={currentPage === pageCount || isPlaceholderData || isLoading}
        >
          &raquo;
        </Button>
      </ButtonGroup>
      {isFetching && (
        <span style={{ float: "right" }}>
          <ThreeDot
            variant="bob"
            color="#32cd32"
            text="Loading ..."
            size="medium"
            textColor="#32cd32"
          />
        </span>
      )}
    </Container>
  );
};

export default NewPagination;
