import React, { useState } from "react";
import { Container, ListGroup, Card, Button, Row, Col } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HotEvent } from "./hotEvent";
import { ReadNews } from "./readNews";
import { LeftSide } from "./leftSide";
import { Barner } from "../home/barner";
import parse from "html-react-parser";

export const Welcome = () => {
  const [read, setRead] = useState(false);
  const { t, i18n } = useTranslation();
  const [newsTitle, setNewsTitle] = useState("");
  const [newsBody, setNewsBody] = useState("");
  const [newsDate, setNewsDate] = useState("");

  const handleReadNews = (data) => {
    setRead(true);
    setNewsDate(data.created_at);
    setNewsBody(data.detail);
    setNewsTitle(data.title);
  };

  return (
    <div>
      <Container fluid={true}>
        <Row>
          <Col lg={4} md={12} sm={12}>
            <LeftSide />
          </Col>
          <Col lg={8} md={12} sm={12}>
            <Barner />
          </Col>
        </Row>
        <br />
        <h1 className="red">{t("welcome.welcome")}</h1>

        <p style={{ lineHeight: "2.2rem" }}>{parse(t("welcome.detail"))}</p>
        <h3>{t("function.intro")}</h3>
        <p>{parse(t("function.func"))}</p>
      </Container>

      <HotEvent />
      <ReadNews
        read={read}
        setRead={setRead}
        newsBody={newsBody}
        newsTitle={newsTitle}
        newsDate={newsDate}
      />
    </div>
  );
};
