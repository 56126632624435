import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FaBullhorn } from "react-icons/fa";
import { NewsEvent } from "./newsEvent";
import { useTranslation } from "react-i18next";
import { ComplainForm } from "./complainForm";
import FreeToll from "../utility/freeToll";
import parse from "html-react-parser";

export const RightSide = () => {
  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  // useEffect(() => {}, [t]);

  return (
    <div style={{ marginTop: "20px" }}>
      <FreeToll />
      <Button
        className="rounded-0 btn-info form-control btn-lg"
        style={{ marginBottom: "0" }}
        onClick={() => setShow(true)}
      >
        <span className="fs-5">
          <FaBullhorn className="fs-3" />
        </span>{" "}
        &nbsp;
        {t("menu.kutoaTaarifa")}
      </Button>
      <Card style={{ marginTop: "0" }}>
        <Card.Body>
          <div style={{ fontSize: "14px", color: "red" }}>
            {parse(t("menu.hint"))}
            <br />
          </div>
          <i style={{ fontSize: "14px", color: "red", fontWeight:"bolder" }}>
            {parse(t("menu.hint1"))}
            <br />
          </i>
          <br />
          <a href="#" onClick={() => setShow(true)}>
            {t("menu.link")}
          </a>
        </Card.Body>
      </Card>
      <NewsEvent />

      <ComplainForm show={show} setShow={setShow} />
    </div>
  );
};
