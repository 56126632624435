import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import IAACA from "../../asset/resources/Constitution of IAACA (2022)_rev2.pdf";
import UNCAC from "../../asset/resources/UN_Convention_Against_Corruption.pdf";
import { BsFileEarmarkPdf } from "react-icons/bs";

const InternationalLaws = () => {
  const [t] = useTranslation();
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th style={{ textAlign: "center" }}>Download</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>United Nations Convention Against Corruption UNCAC</td>
          <td>
            <a href={UNCAC} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td>
            Constitution of the International Association of Anti-Corruption
            Authorities (IAACA)
          </td>
          <td>
            <a href={IAACA} target="new">
              <BsFileEarmarkPdf /> English version
            </a>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default InternationalLaws;
