import React, { useEffect, useState } from "react";
import sw113 from "../../asset/sw_113.gif";
import eng113 from "../../asset/113.gif";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const FreeToll = () => {
  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";

  // const { t } = useTranslation();
  return (
    <Card className="rounded-0">
      {/* <img src={pic} width={"100%"} /> */}
      {defaultLocale === "en" ? (
        <img src={eng113} width={"100%"} />
      ) : (
        <img src={sw113} width={"100%"} />
      )}
    </Card>
  );
};

export default FreeToll;
