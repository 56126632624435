import React, { useState, useEffect } from "react";
import { BsMegaphoneFill } from "react-icons/bs";
import { ListGroup, Form, Spinner, Button, Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
// import { useQuery } from "react-query";
import { useQuery } from "@tanstack/react-query";
import { publicNews } from "../../action/News";
import { DateFormat } from "../utility/dateFormat";
import { ReadNews } from "./readNews";
import { useTranslation } from "react-i18next";
import { ImAttachment } from "react-icons/im";
import { baseURL } from "../../action/baseUrl";
import { Error, LoadingComp } from "../utility";

export const NewsEvent = () => {
  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";

  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  // const [data, setData] = useState([]);
  const [dataa, setDataa] = useState({});
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [read, setRead] = useState(false);
  const [msgError, setMsgError] = useState(null);

  const retrievePosts = async () => {
    const response = await axios.get(`${process.env.REACT_APP_URI}/api/news`);

    return response.data.data;
  };

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["newsHome"],
    queryFn: retrievePosts,
  });

  const handleReadNews = (data) => {
    console.warn(data);
    setRead(true);
    setDataa(data);
  };

  return (
    <>
      <ListGroup variant="flush">
        <Button
          className="btn-secondary btn-lg form-control rounded-0"
          style={{ textAlign: "center" }}
        >
          {t("newsANDevent")}
        </Button>
        {isError && <Error />}
        {isLoading && <LoadingComp />}
        {data?.map((dt, i) => (
          <div
            onClick={() => {
              handleReadNews(dt);
            }}
            key={i}
          >
            <ListGroup.Item className="newsHover">
              <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                <BsMegaphoneFill className="newsIcon" />
                {/* {dt.title} */}
                {defaultLocale === "en" ? dt?.engTitle : dt?.title}
                &nbsp; &nbsp;
                {dt.file && <ImAttachment />}
                <br />
                <span className="postedDate">
                  {t("post")} <DateFormat date={dt.created_at} />
                </span>
              </div>
            </ListGroup.Item>
          </div>
        ))}

        {/* <ListGroup.Item> */}
        <br />
        <Link className="btn btn-info rounded-0" to={"/news/events"}>
          {t("moreNews")}
        </Link>
      </ListGroup>
      <ReadNews read={read} setRead={setRead} dataa={dataa} />
    </>
  );
};
