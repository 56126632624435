import React, { useState } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../action/baseUrl";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { Error, LoadingComp, NewPagination } from "../utility";

const Kanuni = () => {
  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";

  const [currentPage, setCurrentPage] = useState(1);
  const regulation = async (page) => {
    const response = await axios.get(`${baseURL}/api/regulation?page=${page}`);
    return response.data;
  };

  const { isLoading, isError, data, error, isFetching, isPlaceholderData } =
    useQuery({
      queryKey: ["regulationData", currentPage],
      queryFn: () => regulation(currentPage),
      placeholderData: keepPreviousData,
    });
  return (
    <div className="container-fluid">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th style={{ textAlign: "center" }}>Download</th>
          </tr>
        </thead>
        <tbody>
          {isError && <Error />}
          {isLoading && <LoadingComp />}
          {data?.data?.data?.map((dt, i) => (
            <tr>
              <td>{1 + i++}</td>
              <td>{defaultLocale === "en" ? dt?.engDetails : dt?.details}</td>
              <td style={{ textAlign: "center" }}>
                <a href={`${baseURL}/${dt.file}`} target="new">
                  <BsFileEarmarkPdf /> Download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <NewPagination
        currentPage={currentPage}
        total={data?.data.total}
        perPage={data?.data.per_page}
        isPlaceholderData={isPlaceholderData}
        onPageChange={(page) => setCurrentPage(page)}
        isLoading={isLoading}
        isFetching={isFetching}
      />
    </div>
  );
};

export default Kanuni;
