import React, { useEffect } from "react";
import { Container, Row, Card, Col } from "react-bootstrap";
import dgMussa from "../../asset/DG-Mussa.png";
import dgMakarani from "../../asset/DG-Makarani.png";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

export const Background = () => {
  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";

  const { t } = useTranslation();
  useEffect(() => {}, [t]);
  return (
    <Container className="space">
      <h3>{parse(t("history.title"))}</h3>
      <Row>
        <Col md={9} lg={9} sm={12}>
          {parse(t("history.detail"))}
        </Col>
        <Col md={3} lg={3} sm={12}>
          <h3 style={{ textAlign: "center" }}>{t("background.pastDG")}</h3>
          <Card>
            <Card.Img variant="top" src={dgMussa} />
            <Card.Body>
              <Card.Text style={{ textAlign: "center", fontWeight: "bolder" }}>
                {t("background.title")} MUSSA HAJI ALI <br /> 2012 - 2021
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={dgMakarani} />
            <Card.Body>
              <Card.Text style={{ textAlign: "center", fontWeight: "bolder" }}>
                {t("background.title2")} AHMED KHAMIS MAKARANI <br /> 2021 -
                2023
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
