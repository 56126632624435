import React, { useState, useEffect } from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import { listBarnners } from "../../action/Barnners";
import defaultB from "../../asset/default.png";
import { baseURL } from "../../action/baseUrl";
import { Trans, useTranslation } from "react-i18next";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export const Barner = () => {
  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";

  const barnerPosts = async () => {
    const response = await axios.get(`${baseURL}/api/barnner`);
    return response.data.data;
  };

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["barner"],
    queryFn: barnerPosts,
  });

  return (
    <>
      <Container fluid={true} style={{ padding: "0", marginTop: "20px" }}>
        <Carousel fade>
          {isLoading && (
            <Carousel.Item interval={20000}>
              <img src={defaultB} width={"100%"} alt="" />
            </Carousel.Item>
          )}

          {isError && (
            <Carousel.Item>
              <img src={defaultB} width={"100%"} alt="" />
            </Carousel.Item>
          )}

          {data?.length === 0 ? (
            <Carousel.Item>
              <img src={defaultB} width={"100%"} alt="" />
            </Carousel.Item>
          ) : (
            data?.map((dt, i) => (
              <Carousel.Item key={i}>
                <img src={`${baseURL}/${dt.file}`} width={"100%"} alt="" />
                <div className="carousel-caption d-none d-md-block">
                  <h6>
                    {defaultLocale === "en" ? dt?.engDetails : dt?.details}
                  </h6>
                </div>
              </Carousel.Item>
            ))
          )}
        </Carousel>
      </Container>
    </>
  );
};
