import React, { useState, useEffect } from "react";
import { Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { DateFormat } from "../utility/dateFormat";
import { ReadNews } from "./readNews";
import { baseURL } from "../../action/baseUrl";
import { ImAttachment } from "react-icons/im";
import { Error, LoadingComp } from "../utility";
import NewPagination from "../utility/NewPagination";

export const AllNews = () => {
  const [dataa, setDataa] = useState({});
  const [index, setIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(1);
  const [read, setRead] = useState(0);

  const { t } = useTranslation();
  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
  };

  const allNews = async (page) => {
    const response = await axios.get(`${baseURL}/api/all/news?page=${page}`);
    return response.data;
  };

  const { isLoading, isError, data, error, isFetching, isPlaceholderData } =
    useQuery({
      queryKey: ["news", currentPage],
      queryFn: () => allNews(currentPage),
      placeholderData: keepPreviousData,
    });

  const handleReadNews = (data) => {
    console.warn(data);
    setDataa(data);
    setRead(true);
  };

  useEffect(() => {
    setTimeout(() => {
      // dataaa();
    }, 10);
  }, [index, limit]);
  return (
    <>
      <Card>
        <Table striped bordered responsive>
          <tbody>
            {isLoading && <LoadingComp />}

            {isError && <Error />}

            {data?.data?.data?.map((dt, i) => (
              <tr>
                <td>
                  <h4>
                    {1 + i++}.{" "}
                    {defaultLocale === "en" ? dt?.engTitle : dt?.title}
                  </h4>
                  <p>
                    {defaultLocale === "en" ? dt?.engDetails : dt?.details}{" "}
                    {/* {dt.file && <ImAttachment />} */}
                    <a href="#" onClick={() => handleReadNews(dt)}>
                      View news
                    </a>
                  </p>
                  <div>
                    {dt?.file && (
                      <a
                        href={`${baseURL}/${dt?.file}`}
                        className="btn btn-default"
                        target="new"
                      >
                        {t("Attach")} <ImAttachment />
                      </a>
                    )}
                    <span
                      style={{
                        float: "right",
                        fontSize: "smaller",
                        fontStyle: "oblique",
                      }}
                    >
                      {t("post")} <DateFormat date={dt?.created_at} />
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <NewPagination
          currentPage={currentPage}
          total={data?.data.total}
          perPage={data?.data.per_page}
          isPlaceholderData={isPlaceholderData}
          onPageChange={(page) => setCurrentPage(page)}
          isLoading={isLoading}
          isFetching={isFetching}
        />
        {/* <Pagination
          dataLength={data?.total}
          pageCount={data?.last_page}
          handlePageClick={handlePageClick}
        /> */}
        <ReadNews read={read} setRead={setRead} dataa={dataa} />
      </Card>
    </>
  );
};
