import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import bg_eng from "../asset/zaeca-banner.png";
import bg_sw from "../asset/zaeca-banner_sw.png";
import { useTranslation } from "react-i18next";
import { BsFacebook, BsTwitterX, BsInstagram, BsYoutube } from "react-icons/bs";

import Marquee from "react-fast-marquee";

export const Header = () => {
  const { t } = useTranslation();

  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";

  return (
    <>
      <Container
        fluid={true}
        style={{
          paddingTop: "7px",
          paddingBottom: "5px",
          borderBottom: "#78ACD6",
          borderBottomStyle: "solid",
          background: "#e8e8e8",
        }}
      >
        <Container>
          <Row>
            <Col lg={10} md={10} sm={12}>
              {defaultLocale === "en" ? (
                <img src={bg_eng} width={"100%"} alt="" />
              ) : (
                <img src={bg_sw} width={"100%"} alt="" />
              )}
            </Col>
            <Col lg={2} md={2} sm={12} className={"text-center"}>
              <div className="container social">
                <a
                  href="https://www.facebook.com/profile.php?id=100068866193864&mibextid=ZbWKwL"
                  target="new"
                >
                  <BsFacebook style={{ color: "#0766ff" }} />
                </a>
                &nbsp; &nbsp;
                <a
                  href="https://x.com/ZaecaZ?t=Y0AjEBQflqCkVs6VuXuURQ&s=09"
                  target="new"
                >
                  <BsTwitterX style={{ color: "black" }} />
                </a>
                &nbsp; &nbsp;
                <a href="https://www.instagram.com/zaeca.znz" target="new">
                  <BsInstagram style={{ color: "#c8325c" }} />
                </a>
                &nbsp; &nbsp;
                <a
                  href="https://youtube.com/@zaecaonlinetv7808?si=Jn07JOlfGvtdfv7i"
                  target="new"
                >
                  <BsYoutube style={{ color: "#ff1512" }} />
                </a>
              </div>
              <br />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        fluid={true}
        style={{
          paddingLeft: "0",
          paddingRight: "0",
          borderBottom: "#78ACD6",
          borderBottomStyle: "solid",
          fontWeight: "bold",
          color: "red",
        }}
      >
        <Marquee
          behavior="scroll"
          pauseOnHover={true}
          gradientColor={"#5aad42"}
          gradient={true}
        >
          {t("slogan")}
        </Marquee>
      </Container>
    </>
  );
};
